<script lang="ts">
	import { page } from '$app/stores';
	import AdditionalFeaturesGridSection from '$lib/landing-pages/sections/AdditionalFeaturesGridSection.svelte';
	import FAQSection from '$lib/landing-pages/sections/FAQSection.svelte';
	import FeaturesCharts from '$lib/landing-pages/sections/FeaturesCharts.svelte';
	import FeaturesDashboard from '$lib/landing-pages/sections/FeaturesDashboard.svelte';
	import FeaturesMarkets from '$lib/landing-pages/sections/FeaturesMarkets.svelte';
	import FeaturesScreener from '$lib/landing-pages/sections/FeaturesScreener.svelte';
	import HeroSection from '$lib/landing-pages/sections/HeroSection.svelte';
	import InvestorTypeGridSection from '$lib/landing-pages/sections/InvestorTypeGridSection.svelte';
	import PricingSection from '$lib/landing-pages/sections/PricingSection.svelte';
	import TestimonialsSection from '$lib/landing-pages/sections/TestimonialsSection.svelte';
</script>

<!-- CONTAINER -->
<HeroSection numUsers={$page.data.numUsers} />
<FeaturesCharts />
<FeaturesDashboard />
<InvestorTypeGridSection />
<FeaturesScreener />
<FeaturesMarkets />
<AdditionalFeaturesGridSection />
<TestimonialsSection />
<PricingSection />
<FAQSection />
