<script context="module" lang="ts">
	import ChartPageImage from '$images/landingpage/hero/chart-page.png?enhanced';
	import HomePageImage from '$images/landingpage/hero/home-page.png?enhanced';
	import MarketsPageImage from '$images/landingpage/hero/markets-page.png?enhanced';
	import NewsPageImage from '$images/landingpage/hero/news-page.png?enhanced';
	import ScreenerPageImage from '$images/landingpage/hero/screener-page.png?enhanced';

	type Picture = typeof ChartPageImage;

	const HERO_IMAGE_OPTIONS: {
		alt: string;
		buttonIcon: string;
		buttonText: string;
		src: Picture;
	}[] = [
		{
			alt: 'The Tickrr chart page',
			buttonIcon: 'tabler:chart-line',
			buttonText: 'TickrrCharts &trade;',
			src: ChartPageImage
		},
		{
			alt: 'The Tickrr home page',
			buttonIcon: 'solar:home-2-bold',
			buttonText: 'Dashboard',
			src: HomePageImage
		},
		{
			alt: 'The Tickrr markets page',
			buttonIcon: 'solar:globus-bold',
			buttonText: 'Markets',
			src: MarketsPageImage
		},
		{
			alt: 'The Tickrr screener page',
			buttonIcon: 'solar:target-bold',
			buttonText: 'Screener',
			src: ScreenerPageImage
		},
		{
			alt: 'The Tickrr news page',
			buttonIcon: 'solar:documents-bold',
			buttonText: 'News',
			src: NewsPageImage
		}
	];
</script>

<script lang="ts">
	import { getStores } from '@tickrr/ui/stores';
	import { onMount } from 'svelte';

	import LovedBy from '../common/LovedBy.svelte';

	const { isMobileStore } = getStores(['isMobileStore']);

	export let sectionId: string = 'hero';
	export let areTransitionsEnabled: boolean = true;
	export let numUsers: number;

	let isMounted: boolean = false;

	let activeImage: (typeof HERO_IMAGE_OPTIONS)[number] = HERO_IMAGE_OPTIONS[0];

	onMount(() => {
		isMounted = true;
	});
</script>

<section
	id={sectionId}
	class="relative max-tablet:mt-6 max-tablet:space-y-10 tablet:mt-12 tablet:space-y-12"
>
	<!-- HEADING, SUBHEADING, CTA -->
	<!-- 
	We hardcode transition instead of using `fadeInOnIntersect` 
	because the element is above-the-fold and we want to improve our LCP score as much as possible.
	-->
	<div
		class="relative w-full"
		class:opacity-0={!isMounted && areTransitionsEnabled}
		class:translate-y-12={!isMounted && areTransitionsEnabled}
		style="
		transition-property: opacity, transform; 
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); 
		transition-duration: 1000ms;
		"
	>
		{#if $isMobileStore}
			<h1 class="mb-3 text-center text-[2.5rem] font-black leading-10 text-surface-50">
				<!-- Next-gen charting & market analysis -->
				<!-- <u>The</u> Next-gen charting & market analysis platform -->
				<!-- Master the market with next-gen charting tools -->
				Supercharge your trading potential
				<!-- Modern charting & market analysis <br /> tools for investors -->
			</h1>
		{:else}
			<h1 class="mb-6 text-7xl font-black text-surface-50">
				<!-- Modern charting & market <br class="max-laptop:hidden" /> analysis tools for
				investors -->
				<!-- Maximize your trading potential with next-gen tools -->
				<!-- Supercharge your trading potential with modern charts and market analysis tools -->
				Supercharge your trading with advanced charts & analysis tools
				<!-- Chart Smarter. -->
			</h1>
		{/if}

		<p class="mb-6 max-w-3xl text-xl max-tablet:text-center tablet:text-surface-50">
			Tickrr is a next-generation platform designed to help you research the market, analyze
			assets, and drive your profits to the moon.
			<!-- , regardless of whether you are a day trader,
			value investor, or just a beginner. -->
		</p>

		<div
			class="flex w-fit items-center gap-6 max-tablet:mx-auto max-tablet:w-full max-tablet:max-w-xs max-tablet:flex-col"
		>
			<a href="/subscribe" class="variant-filled btn w-64 flex-col gap-y-2 py-3">
				<span>Start your free trial</span>
			</a>
			<LovedBy {numUsers} />
		</div>
	</div>

	<!-- IMAGE -->
	<!-- 
	We hardcode transition instead of using `fadeInOnIntersect` 
	because the element is above-the-fold and we want to improve our LCP score as much as possible.
	-->
	<div
		class="relative rounded-container-token max-tablet:-mr-6 max-tablet:aspect-[3/4]"
		class:opacity-0={!isMounted && areTransitionsEnabled}
		class:translate-y-12={!isMounted && areTransitionsEnabled}
		style="
		transition-property: opacity, transform; 
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); 
		transition-duration: 1000ms;
		transition-delay: 500ms;
		"
	>
		<enhanced:img
			alt={activeImage.alt}
			loading="eager"
			src={activeImage.src}
			class="
			w-full border border-hover rounded-container-token
			max-tablet:absolute max-tablet:left-0 max-tablet:top-0 max-tablet:h-full max-tablet:w-[720px]
			max-tablet:rounded-br-none max-tablet:rounded-tr-none max-tablet:border-r-0
			max-tablet:object-cover max-tablet:object-left
			"
		/>

		<div class="mx-auto mt-12 flex max-w-5xl gap-x-4 max-tablet:hidden">
			{#each HERO_IMAGE_OPTIONS as opt (opt)}
				<label
					class="variant-filled-surface btn w-full gap-x-1 rounded-full hover:cursor-pointer"
					class:border={opt === activeImage}
					class:border-primary-500={opt === activeImage}
				>
					<input
						name="radio-buttons"
						checked={opt === activeImage}
						type="radio"
						class="!mr-0 hidden"
						on:change={() => (activeImage = opt)}
					/>
					<iconify-icon
						inline
						icon={opt.buttonIcon}
						class="text-surface-50"
						class:!text-primary-500={opt === activeImage}
					/>
					<span
						class="pointer-events-none text-surface-50"
						class:!text-primary-500={opt === activeImage}
					>
						<!-- eslint-disable-next-line svelte/no-at-html-tags -->
						{@html opt.buttonText}
					</span>
				</label>
			{/each}
		</div>
	</div>
</section>
