<script context="module" lang="ts">
	import DayTraderImage from '$images/art/neo-1.jpeg?enhanced';
	import CryptoTraderImage from '$images/art/neo-2.jpeg?enhanced';
	import ValueInvestorImage from '$images/art/neo-6.jpeg?enhanced';
	import BeginnerInvestorImage from '$images/art/neo-7.jpeg?enhanced';

	type Picture = typeof DayTraderImage;

	type BentoItem = {
		colSize?: string;
		description: string;
		href: string;
		image: Picture;
		title: string;
	};

	const BENTO_ITEMS: BentoItem[] = [
		{
			colSize: 'tablet:col-span-6',
			description:
				'Tickrr is perfect for day traders who want to research trades quickly and easily.',
			href: '/for-day-traders',
			// image: '/images/art/spaceship-3.jpeg',
			// // image: '/images/art/robot-2.jpeg',
			// image: '/images/art/spaceman-6.jpeg',
			// image: '/images/art/anime-town-2.jpeg',
			image: DayTraderImage,
			title: 'Day Traders'
		},
		{
			colSize: 'tablet:col-span-3',
			description:
				'Tickrr can help crypto traders discover new coins and make split-investing decisions.',
			href: '/for-crypto-traders',
			// image: '/images/art/spaceman-2.jpeg',
			// image: '/images/art/anime-swing-3.jpeg',
			image: CryptoTraderImage,
			title: 'Crypto Traders'
		},
		{
			colSize: 'tablet:col-span-3',
			description:
				'Value investors can use Tickrr to research stocks, identify value-based opportunities, and manage their portfolio.',
			href: '/for-value-investors',
			// image: '/images/art/robot-3.jpeg',
			// image: '/images/art/anime-businessman-4.jpeg',
			image: ValueInvestorImage,
			title: 'Value Investors'
		},
		{
			colSize: 'tablet:col-span-6',
			description:
				'New to investing? Tickrr is perfect for you! We will help you learn and grow with you.',
			href: '/for-beginners',
			// image: '/images/art/spaceman-studying-1.jpeg',
			// image: '/images/art/anime-library-2.jpeg',
			image: BeginnerInvestorImage,
			title: 'Beginner Investors'
		}
	];
</script>

<script lang="ts">
	import { fadeInOnIntersect } from '@tickrr/ui/actions';

	import SectionHeading from '../common/SectionHeading.svelte';

	export let sectionId: string = 'solutions';
	export let areTransitionsEnabled: boolean = true;
</script>

<section
	id={sectionId}
	class="space-y-12"
	use:fadeInOnIntersect={{
		duration: 1250,
		enabled: areTransitionsEnabled,
		translate: 'translate-y-40'
	}}
>
	<div class="w-full">
		<SectionHeading
			classes="max-w-3xl"
			linkProps={{ href: '/signup', text: 'Join now, completely free' }}
		>
			<svelte:fragment slot="top-text">Perfect for any strategy</svelte:fragment>
			<svelte:fragment slot="heading">Made for Every Type of Trader</svelte:fragment>
			<svelte:fragment slot="subheading">
				Regardless of how you choose to invest, Tickrr helps you research the market and
				drive your profits to the moon.
			</svelte:fragment>
		</SectionHeading>
	</div>

	<div class="grid gap-4 tablet:grid-cols-6">
		{#each BENTO_ITEMS as item (item)}
			<a
				href={item.href}
				class="
                group relative flex h-96 flex-col justify-end overflow-hidden bg-surface-700 p-6
                transition-all duration-300 rounded-container-token
                after:absolute after:inset-0 after:bg-gradient-to-b after:from-transparent after:via-surface-900/20 after:to-surface-900
                {item.colSize}
                "
			>
				<enhanced:img
					alt={item.title}
					loading="lazy"
					src={item.image}
					class="absolute inset-0 h-full w-full transform object-cover object-center opacity-80 transition-all duration-700 group-hover:scale-105 group-hover:opacity-100"
				/>
				<div class="z-10">
					<h3 class="mb-2 mt-auto text-5xl font-black text-surface-50">{item.title}</h3>
					<p>
						{item.description}
					</p>
				</div>
			</a>
		{/each}
	</div>
</section>
